.explainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
}

.stage {
  width: 100%;
}

.table {
  background: white;
}

.explainer {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}

.scene {
  max-width: 500px;
}

.explainer .pointer {
  position: absolute;
  width: 3%;
  z-index: 1;
}

.actor {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}
