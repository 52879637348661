.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.canvas.fixed {
  position: fixed;
}

.hexfixed {
  background: url('shared/assets/icons/hextile.svg') fixed;
}

.hexbg {
  background: url('shared/assets/icons/hextile.svg');
}
