.fadeIn {
  opacity: 0;
}

.slideLeft {
  transform: translateX(0.75rem);  
}

.slideRight {
  transform: translateX(-0.75rem);
}

.active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s linear, transform 0.3s linear;
}
