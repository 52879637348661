.billing {
  top: 0;
  bottom: 0;
  width: 50%;
  transition: transform 200ms linear;
}

.billing.monthly {
  transform: translateX(0);
}

.billing.yearly {
  transform: translateX(100%);
}

.foreground {
  transition: color 200ms linear;
}

.card {
  display: grid;
  grid-template-rows: minmax(40px, max-content) minmax(40px, max-content) minmax(90px, max-content) minmax(60px, max-content) max-content;
}

.card.animated {
  animation: shadowOut 0.5s;
}

.card.animated:hover {
  box-shadow: 0 4px 15px 10px rgb(0 0 0 / 0.1);
  animation: shadowIn 0.5s;
}

@keyframes shadowIn {
  0% {
    box-shadow: 0 4px 0 0 rgb(0 0 0 / 0.1);
  }
  100% {
    box-shadow: 0 4px 15px 10px rgb(0 0 0 / 0.1);
  }
}

@keyframes shadowOut {
  0% {
    box-shadow: 0 4px 15px 10px rgb(0 0 0 / 0.1);
  }
  100% {
    box-shadow: 0 4px 0 0 rgb(0 0 0 / 0.1);
  }
}

.hex {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -10000;
  background: url('shared/assets/icons/hextile.svg');
}

