.outer {
  position: relative;
  box-shadow: 0px -10px 20px 8px rgba(255,255,255,1);
  z-index: 1;
}

.flexible {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: theme('screens.md')) {
  .flexible {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "image-internal image-alone image-embed"
      "title-internal title-alone title-embed"
      "blurb-internal blurb-alone blurb-embed";
    grid-column-gap: theme(space.8);
  }

  .image.internal {
    grid-area: image-internal;
  }

  .image.alone {
    grid-area: image-alone;
  }

  .image.embed {
    grid-area: image-embed;
  }

  .title.internal {
    grid-area: title-internal;
  }

  .title.alone {
    grid-area: title-alone;
  }

  .title.embed {
    grid-area: title-embed;
  }

  .blurb.internal {
    grid-area: blurb-internal;
  }

  .blurb.alone {
    grid-area: blurb-alone;
  }

  .blurb.embed {
    grid-area: blurb-embed;
  }
}
