.anchor {
  display: flex;
  flex-direction: column;
}

.submenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: fadeIn 250ms ease-in-out;
}

.submenu.hidden {
  display: none;
}

.chevron {
  transform: rotate(-90deg);
  transition: transform 100ms;
}

.chevron.expanded {
  transform: rotate(0deg);
}

@media (min-width: theme('screens.md')) {
  .anchor {
    align-items: center;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
  }

  .submenu {
    display: none;
    position: absolute;
    top: calc(100% - 4px);
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    border: 1px solid theme('colors.purple.200');
    border-radius: 4px;
    background: white;
    width: max-content;
    min-width: 100px;
    max-width: 200px;
    animation: fadeIn 250ms ease-in-out;
  }

  .submenu.fading {
    display: block;
    opacity: 0;
    animation: fadeOut 250ms ease-in-out;
  }
  
  .anchor:hover > .submenu {
    display: block;
  }  
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}
