@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: "Nunito Sans 10pt";
  src: url('shared/assets/font/NunitoSans_10pt/NunitoSans_10pt-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: "Source Code Pro";
  src: url('shared/assets/font/SourceCodePro/SourceCodePro-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: "Coming Soon";
  src: url('shared/assets/font/ComingSoon/ComingSoon-Regular.ttf');
  font-weight: 400;
}

html, body, #root {
  height: 100%;
}

#hello {
  background-color: red;
  color: lightgreen;
}