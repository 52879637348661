.chevron {
  display: inline;
  transform: rotate(-90deg);
  transition: transform 100ms;
}

.chevron.expanded {
  transform: rotate(0deg);
}

.chevron {
  display: inline;
  transform: rotate(-90deg);
  transition: transform 100ms;
}
